import type { CustomerProductsRequestType, OrderType } from '@dtp/membership-service-types';
import { AddonProductType } from '@dtp/membership-service-types';
import { MembersFormType } from '../../../../../types/membersFormType';
import { MembershipFormType } from '../../../../../types/membershipFormType';
import { AddonsFormType } from '../../../../../types/addonsFormType';

export const createOrderPayload = (
  membersData: Partial<MembersFormType>,
  membershipData: Partial<MembershipFormType>,
  returnUrl: string,
  campaignCode?: string,
  customerId?: string,
  addonData?: AddonsFormType,
  availableAddonsData?: Record<string, AddonProductType>,
): OrderType => {
  const mainMember: CustomerProductsRequestType = {
    profile: {
      isMainHouseholdMember: true,
      firstName: membersData?.mainMember?.firstName,
      lastName: membersData?.mainMember?.lastName,
      email: membersData?.mainMember?.eMail,
      phoneNumber: membersData?.mainMember?.phoneNumber ? `+47${membersData?.mainMember?.phoneNumber}` : null,
      dateOfBirth: `${membersData?.mainMember?.birthDate.year.toFixed(0)}-${membersData?.mainMember?.birthDate.month
        .toFixed(0)
        .padStart(2, '0')}-${membersData?.mainMember?.birthDate.day.toFixed(0).padStart(2, '0')}`, // 2021-06-10
      gender: membersData?.mainMember?.gender,
      address: membersData?.mainMember?.address,
      postalCode: membersData?.mainMember?.zip,
    },
    consents:
      membersData?.mainMember?.consents
        ?.filter((consent) => consent?.id)
        .map((consent) => ({
          consentNumber: consent.id,
          consented: consent.consented || false,
        })) || [],
    products: [
      {
        productId: membershipData.product.productId.toString(),
        isHouseholdProduct: membershipData.product.isHouseholdProduct,
        props: {
          ...(membersData.mainMember?.clubNumber?.value
            ? { clubNumber: membersData.mainMember?.clubNumber?.value }
            : {}),
        },
      },
    ],
  };
  if (customerId) {
    mainMember.customerId = customerId;
  }
  const otherMembers =
    (membersData.otherMembers &&
      membersData.otherMembers.map((member) => ({
        profile: {
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.eMail,
          phoneNumber: member.phoneNumber ? `+47${member.phoneNumber}` : null,
          dateOfBirth: `${member.birthDate.year.toFixed(0)}-${member.birthDate.month
            .toFixed(0)
            .padStart(2, '0')}-${member.birthDate.day.toFixed(0).padStart(2, '0')}`,
          gender: member.gender,
          isMainHouseholdMember: false,
        },
        consents: [],
        products: [
          {
            productId: membershipData.product.productId.toString(),
            isHouseholdProduct: membershipData.product.isHouseholdProduct,
            props: {},
          },
        ],
      }))) ||
    [];

  const customerProductsRequestsMap = [mainMember, ...otherMembers].reduce(
    (acc: Record<string, CustomerProductsRequestType>, cur, i) => {
      acc[i.toString()] = cur;
      return acc;
    },
    {},
  );

  if (addonData) {
    Object.entries(addonData).forEach(([productId, { members, props }]) => {
      const currentProduct = availableAddonsData ? availableAddonsData[productId] : undefined;
      members.forEach((tempID) => {
        if (currentProduct) {
          customerProductsRequestsMap[tempID].products.push({
            ...{ productId: currentProduct.productNumber, isHouseholdProduct: false },
            ...(props ? { props } : {}),
          });
        }
      });
    });
  }

  const result: OrderType = {
    customerProductsRequests: Object.values(customerProductsRequestsMap),
    salesAgent: 'innmelding@naf.no',
    salesChannel: 'web',
    salesLocationNumber: '86646379',
    returnUrl,
  };

  if (campaignCode) {
    result.campaignCode = campaignCode;
  }

  return result;
};

import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { DateWrapper, Input } from '@naf/input';
import { Text } from '@naf/text';
import { UserInteraction } from '@naf/user-interaction';
import { GridCol } from '@naf/grid';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 41px;
`;

const StyledForm = styled.form``;

const StyledGridCol = styled(GridCol)``;

const FirstHeading = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space8};
  @media (min-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space16};
  }
`;

const Description = styled(Text)`
  margin: ${spacing.space32} 0 0 0;
`;

const SecondHeading = styled(Text)`
  margin-top: 0;
  margin-bottom: 0;
  @media (min-width: ${breakpoints.m}) {
    margin-bottom: 0;
  }
`;

const Heading = styled(Text)`
  margin-top: ${spacing.space48};
  margin-bottom: ${spacing.space8};
  @media (min-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space16};
  }
`;

const SubHeadingText = styled.p`
  margin-bottom: ${spacing.space32};
  @media (min-width: ${breakpoints.m}) {
    margin-bottom: 28px;
  }
`;

const ControllersWrapper = styled.div`
  max-width: 544px;

  @media (max-width: ${breakpoints.m}) {
    max-width: 100%;

    > div {
      width: 100%;
    }
  }
`;

const FieldWrapper = styled.div`
  margin-top: ${spacing.space24};
`;

const FirstName = styled.div`
  @media (min-width: ${breakpoints.m}) {
    margin-right: ${spacing.space32};
    display: inline-flex;
  }
`;

const LastName = styled.div`
  margin-top: 0;

  @media (max-width: ${breakpoints.m}) {
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.m}) {
    display: inline-flex;
  }
`;

const BirthDateWrapper = styled(DateWrapper)`
  margin-top: -8px;
  > :not(first-child) {
    margin-right: 12px;
  }
`;

const Zip = styled.div`
  @media (min-width: ${breakpoints.m}) {
    margin-right: ${spacing.space32};
    display: inline-flex;
  }
`;

const City = styled.div`
  margin-bottom: ${spacing.space24};
  @media (min-width: ${breakpoints.m}) {
    margin-top: 0;
    display: inline-flex;
  }
`;

const FullWidthTextField = styled(Input)`
  width: 100%;
  @media (min-width: ${breakpoints.m}) {
    width: 544px;
  }
`;

const ResponsiveTextField = styled(Input)`
  @media (max-width: ${breakpoints.m}) {
    width: 100%;
  }
`;

const StyledUserInteraction = styled(UserInteraction)`
  max-width: 496px;
  margin-bottom: ${spacing.space24};
  margin-top: ${spacing.space48};
  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    max-width: calc(100% - ${spacing.space48});
    margin-top: ${spacing.space24};
    margin-bottom: 0;
  }
  @media (max-width: ${breakpoints.s}) {
    width: 100%;
    max-width: calc(100% - ${spacing.space32});
  }
`;

const TermsConsentText = styled.p`
  margin-top: ${spacing.space32};
  margin-bottom: 0;
`;

export default {
  ContentWrapper,
  FormWrapper,
  StyledForm,
  StyledGridCol,
  FirstHeading,
  Description,
  SecondHeading,
  Heading,
  SubHeadingText,
  ControllersWrapper,
  FieldWrapper,
  FirstName,
  LastName,
  BirthDateWrapper,
  Zip,
  City,
  FullWidthTextField,
  ResponsiveTextField,
  StyledUserInteraction,
  TermsConsentText,
};

import { Controller, UseFormReturn } from 'react-hook-form';
import xor from 'lodash/xor';
import React from 'react';
import { AddonProductType, ProductActivation } from '@dtp/membership-service-types';
import { useStateMachine } from 'little-state-machine';
import { TextVariant } from '@naf/text';
import loadable from '@loadable/component';
import S from './Styles';
import { updateAction } from '../../../lib/updateAction';
import { formattedPrice } from '../../../../../lib/formattedPrice';

const FormFieldController = loadable(() => import('../../../../../components/Form/FormFieldController'), {
  resolveComponent: (components) => components.FormFieldController,
});

interface Props {
  availableAddOnProducts?: (AddonProductType & { isRequiredAddOn?: boolean })[];
  control: UseFormReturn['control'];
  handleSubmit: UseFormReturn['handleSubmit'];
  onSubmit?: (values: Record<string, string | string[] | boolean | undefined>) => any;
  errors: UseFormReturn['formState']['errors'];
  watch: UseFormReturn['watch'];
  setValue: UseFormReturn['setValue'];
  getValues: UseFormReturn['getValues'];
  unregister: UseFormReturn['unregister'];
}

export const AddonForm = ({
  onSubmit,
  availableAddOnProducts,
  control,
  handleSubmit,
  errors,
  watch,
  unregister,
  getValues,
  setValue,
}: Props) => {
  // Get current form state from session storage
  const {
    state: { membersForm, addonsForm },
  } = useStateMachine({ updateAction });

  const members = [membersForm.mainMember, ...(membersForm.otherMembers || [])];
  return (
    <S.StyledForm onSubmit={() => onSubmit && handleSubmit(onSubmit)}>
      {availableAddOnProducts?.map((product) => {
        if (!product.isHouseholdProduct && product.productActivationType === ProductActivation.PaymentProduct) {
          const productValues = watch(product.productNumber);
          watch((values) => {
            if (
              values[product.productNumber]?.members?.length === 0 &&
              Object.values(values[product.productNumber]).length > 1
            ) {
              Object.values(product.fieldComponents).forEach((field) => {
                unregister(`${product.productNumber}.props.${field.id}`);
              });
            }
          });
          // For every payment product not a household product -> Add to the selected new members
          return (
            <S.FieldWrapper key={product.productNumber} $error={!!errors[product.productNumber]}>
              <Controller
                name={`${product.productNumber}.members`}
                control={control}
                defaultValue={
                  addonsForm[product.productNumber]?.members ? addonsForm[product.productNumber]?.members : []
                }
                render={({ field: { value, onChange } }) => {
                  const currentPrice = product.productAppPrice[value.length.toString()]?.itemTotalPrice || 0;
                  const nextPrice = product.productAppPrice[(value.length + 1).toString()]?.itemTotalPrice || 0;
                  const priceDifference = nextPrice - currentPrice;
                  return (
                    <S.CheckboxWrapper>
                      <S.LabelWrapper>
                        <S.StyledLabel variant={TextVariant.ArticleTextSubHeader}>{product.productName}</S.StyledLabel>
                        {(product.shortDescription && <S.StyledTooltip text={product.shortDescription} />) || null}
                      </S.LabelWrapper>
                      <S.PriceSubLabel>
                        {priceDifference && priceDifference > 0
                          ? `+ ${formattedPrice(priceDifference)} kr per pers`
                          : `Gratis`}
                      </S.PriceSubLabel>
                      {members.map((member, index) => (
                        <S.StyledCheckbox
                          label={`${member?.firstName} ${member?.lastName}`}
                          name={`${member?.firstName}-${member?.lastName}`}
                          key={`${product.productNumber}-${member?.firstName}-${member?.lastName}`}
                          checked={value.includes(index.toString())}
                          onChange={() => onChange(xor(value as string[], [index.toString()]))}
                        />
                      ))}
                    </S.CheckboxWrapper>
                  );
                }}
              />
              {productValues && productValues.members.length > 0 && (
                <S.FormFieldsWrapper>
                  <FormFieldController
                    control={control}
                    fieldComponents={product.fieldComponents}
                    errors={errors}
                    fieldNamePrefix={`${product.productNumber}.props`}
                  />
                </S.FormFieldsWrapper>
              )}
            </S.FieldWrapper>
          );
        }

        if (product.isHouseholdProduct && product.productActivationType === ProductActivation.PaymentProduct) {
          const productValues = watch(product.productNumber);
          watch((values) => {
            if (
              values[product.productNumber]?.members?.length === 0 &&
              Object.values(values[product.productNumber]).length > 1
            ) {
              Object.values(product.fieldComponents).forEach((field) => {
                unregister(`${product.productNumber}.props.${field.id}`);
              });
            }
          });
          // For every payment product that is a household product -> Add to all new members
          return (
            <S.FieldWrapper key={product.productNumber} $error={!!errors[product.productNumber]}>
              <Controller
                name={`${product.productNumber}.members`}
                control={control}
                defaultValue={
                  addonsForm[product.productNumber]?.members ? addonsForm[product.productNumber]?.members : []
                }
                render={({ field: { value, onChange } }) => (
                  <S.StyledCheckbox
                    label={product.productName}
                    name={product.productName}
                    checked={value.length === members.length}
                    onChange={() =>
                      onChange(
                        xor(
                          value as string[],
                          [members.keys()].map((e) => e.toString()),
                        ),
                      )
                    }
                  />
                )}
              />
              {productValues && productValues.members.length > 0 && (
                <FormFieldController
                  control={control}
                  fieldComponents={product.fieldComponents}
                  errors={errors}
                  fieldNamePrefix={`${product.productNumber}.props`}
                />
              )}
            </S.FieldWrapper>
          );
        }

        if (product.productActivationType === ProductActivation.ActivationProduct) {
          const productValues = watch(product.productNumber);
          if (getValues(product.productNumber)?.members?.length === 0 && product.isRequiredAddOn) {
            // If the product is required, add it to the form with the main member as the only member
            setValue(product.productNumber, { members: ['0'] });
          }
          watch((values) => {
            if (
              values[product.productNumber]?.members?.length === 0 &&
              Object.values(values[product.productNumber]).length > 1
            ) {
              // if no members are selected and not required, remove product props from the form
              Object.values(product.fieldComponents).forEach((field) => {
                unregister(`${product.productNumber}.props.${field.id}`);
              });
            }
          });
          // For every activation product -> Add to the household member (first new member)
          return (
            <S.FieldWrapper key={product.productNumber} $error={!!errors[product.productNumber]}>
              <Controller
                name={`${product.productNumber}.members`}
                control={control}
                defaultValue={
                  addonsForm[product.productNumber]?.members ? addonsForm[product.productNumber]?.members : []
                }
                render={({ field: { value, onChange } }) => (
                  <S.LabelWrapper>
                    {product.isRequiredAddOn ? (
                      <S.StyledLabel variant={TextVariant.ArticleTextSubHeader}>{product.productName}</S.StyledLabel>
                    ) : (
                      <S.StyledCheckbox
                        label={product.productName}
                        name={product.productName}
                        checked={value.includes('0')}
                        onChange={() => onChange(xor(value as string[], ['0']))}
                      />
                    )}
                    {(product.shortDescription && <S.StyledTooltip text={product.shortDescription} />) || null}
                  </S.LabelWrapper>
                )}
              />
              {productValues && productValues.members.length > 0 && product.fieldComponents.length > 0 && (
                <S.FormFieldsWrapper>
                  <FormFieldController
                    control={control}
                    fieldComponents={product.fieldComponents}
                    errors={errors}
                    fieldNamePrefix={`${product.productNumber}.props`}
                  />
                </S.FormFieldsWrapper>
              )}
            </S.FieldWrapper>
          );
        }

        return null;
      })}
    </S.StyledForm>
  );
};

import styled from 'styled-components';
import { Input, Label } from '@naf/input';
import { Checkbox } from '@naf/checkbox';
import { nafColor } from '@nafcore/theme';
import { fontStyle, spacing } from '@naf/theme';
import { Tooltip } from '@naf/parts';

const StyledForm = styled.form`
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
`;

const FieldWrapper = styled.div<{ $error?: boolean }>`
  margin-top: ${spacing.space16};
  margin-bottom: ${spacing.space24};
  border: 1px solid ${(props) => (props.$error ? nafColor.information.errorDark : nafColor.neutral.neutral3)};
  border-radius: 2px;
  padding: 12px 14px 0 14px;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: flex-center;
  align-items: center;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 0;
  width: max-content;

  div {
    width: max-content !important;
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: ${spacing.space4};
`;

const PriceSubLabel = styled.h6`
  margin: 0;
  margin-bottom: ${spacing.space16};
  ${fontStyle.bodyText.small};
  color: ${nafColor.neutral.neutral5};
  line-height: 1rem;
  flex-basis: 100%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${spacing.space8};
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: ${spacing.space12};
  }
`;

const FormFieldsWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing.space16};

  .errorContainer {
    max-width: 450px;
  }
`;

export default {
  StyledForm,
  FieldWrapper,
  StyledInput,
  LabelWrapper,
  StyledLabel,
  StyledTooltip,
  PriceSubLabel,
  CheckboxWrapper,
  StyledCheckbox,
  ToggleWrapper,
  FormFieldsWrapper,
};
